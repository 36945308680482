<template>
  <span class="font-weight-bold">{{
    !valueIsEmpty ? arredondarValue(valueAsNumberOrNull) : "- - -"
  }}</span>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
    },
    mediaMinima: {
      type: [Number],
      default: 7,
    },
    max: {
      type: Number,
      default: 10,
    },
    arredondamento: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value(val) {
      if (val > this.max) {
        this.$emit("change", parseFloat(this.max, 10));
      }
      this.verifyResultado(val);
    },
  },
  created() {
    if (this.value > this.max) {
      console.log("inci", this.value);
      this.$emit("change", parseFloat(this.max, 10));
    }
  },
  computed: {
    valueAsNumberOrNull() {
      return !this.valueIsEmpty ? parseFloat(this.value, 10) : null;
    },
    valueIsEmpty() {
      if (!this.value) {
        return "- - -";
      }
      // eslint-disable-next-line no-restricted-globals
      return this.value === null || this.value === undefined || isNaN(this.value);
    },
  },

  methods: {
    verifyResultado(val) {
      console.log("val", val);
      this.$emit("changeResultado", parseFloat(val, 10));
    },
    arredondarValue(number) {
      if (this.arredondamento === 1) {
        // Separa a parte inteira e decimal do número
        const integerPart = Math.floor(number);
        const decimalPart = number - integerPart;

        // Define os limites para cada arredondamento
        const lowerLimit = 0.3;
        const upperLimit = 0.7;

        const deci = parseFloat(decimalPart.toFixed(1));

        // Verifica em qual intervalo o número está e arredonda conforme necessário
        if (deci >= lowerLimit && deci <= upperLimit) {
          return integerPart + 0.5; // arredonda para baixo
        }
        if (deci >= 0.8) {
          return Math.ceil(number).toFixed(1);
        }

        return Math.floor(number).toFixed(1);
      }
      return number.toFixed(1);
    },
  },
};
</script>
