<template>
  <main-template>
    <v-row>
      <v-col cols="12">
        <h2 class="text-h5 mb-2">{{ escola.descricao }}</h2>
        <e-label>Pesquise uma unidade escolar aqui</e-label>
        <v-autocomplete
          v-model="escola"
          :items="this.franquiasList"
          item-text="descricao"
          item-value="id"
          dense
          return-object
          filled
          label="Pesquise uma escola"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <!--  -->
    <v-row>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" v-if="escola && escola.id">
        <v-card color="primary lighten-1" @click="openModalSelectMonths(escola.id)">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> fa fa-chalkboard-teacher </v-icon>
            <span class="title font-weight-light">Financeiro por mês - Escola</span>
          </v-card-title>

          <v-card-text class="subtitle font-weight-bold"> Financeiro Escola</v-card-text>
        </v-card>
      </v-col>
      <!--  -->
      <v-col cols="12" xs="12" sm="12" md="6" lg="4">
        <v-card color="primary lighten-1" @click="openModalSelectMonthsTurma()">
          <template slot="progress">
            <v-progress-linear
              color="primary lighten-1"
              height="5"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title>
            <v-icon large left> mdi-book-variant </v-icon>
            <span class="title font-weight-light">Financeiro por mês - Turma</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold"> Financeiro Turma </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <e-select-months-school-modal
      @dialogChange="dialogSchool = $event"
      :dialog="dialogSchool"
      confirmText="Gerar relatório"
      cancelText="Fechar"
      @changeAction="financeiroPorEscola"
    ></e-select-months-school-modal>
    <e-select-months-turma-modal
      @dialogChange="dialogTurma = $event"
      :dialog="dialogTurma"
      confirmText="Gerar relatório"
      cancelText="Fechar"
      @changeAction="financeiroPorTurma"
    ></e-select-months-turma-modal>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Relatórios",
  data() {
    return {
      escola: {
        descricao: null,
        id: null,
      },
      turma: {
        descricao: null,
        id: null,
      },
      dialogSchool: false,
      dialogTurma: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquiasList", "carregandoFranquias"]),
  },
  methods: {
    openModalSelectMonths(franquia_id) {
      this.escola.id = franquia_id;
      this.dialogSchool = !this.dialogSchool;
    },
    openModalSelectMonthsTurma() {
      this.dialogTurma = !this.dialogTurma;
    },
    async financeiroPorEscola(mes) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.financeiroPorEscola(
          parseInt(this.escola.id, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async financeiroPorTurma(turma, mes) {
      try {
        this.$loaderService.open("Carregando dados...");
        await this.$services.relatoriosService.financeiroPorTurma(
          parseInt(turma.id, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
  mounted() {
    this.$loaderService.open("Carregando dados...");
    this.escola = this.user.franquia;
    this.$loaderService.close();
  },
};
</script>
