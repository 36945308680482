<template>
  <div class="text-center">
    <v-dialog v-model="dialog" persistent width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione os Campos </v-card-title>
        <ValidationProvider name="Mês" rules="required" v-slot="{ errors }">
          <v-select
            :error-messages="errors"
            :items="meses"
            item-text="descricao"
            item-value="id"
            v-model="selectMonth"
            return-object
            label="Selecione um mês"
            solo
          ></v-select>
        </ValidationProvider>
        <v-divider></v-divider>
        <ValidationProvider name="Turma" rules="required" v-slot="{ errors }">
          <v-select
            :error-messages="errors"
            :items="turmasList.filter((item) => !item.deleted_at)"
            :item-text="(item) => item.descricao"
            v-model="turmaSelecionada"
            return-object
            label="Selecione uma turma"
            solo
          ></v-select>
        </ValidationProvider>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="text" @click="$emit('dialogChange', !dialog)"> {{ cancelText }} </v-btn>
          <v-btn color="primary" text @click="emitChangeAction" :disabled="!validForm">
            {{ confirmText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    cancelText: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("Turmas", ["turmasList"]),
    validForm() {
      return !!this.selectMonth && !!this.turmaSelecionada;
    },
  },
  data() {
    return {
      selectMonth: null,
      turmaSelecionada: null,
      meses: [],
    };
  },
  methods: {
    ...mapActions("Turmas", ["loadTurmas"]),
    emitChangeAction() {
      if (this.validForm) {
        this.$emit("changeAction", this.turmaSelecionada, this.selectMonth);
      }
    },
  },
  async mounted() {
    try {
      this.meses = await this.$services.anosService.meses();
      await this.loadTurmas();
    } catch (error) {
      this.$handleError(error);
    }
  },
};
</script>

<style lang="scss" scoped></style>
