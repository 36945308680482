<template>
  <v-dialog v-model="open" persistent max-width="450px">
    <v-card>
      <v-card-title />
      <v-card-text>
        <div class="d-flex align-center">
          <v-icon size="75" color="warning"> fa fa-warning </v-icon>
          <span class="ml-4 text-body-2">
            Ao fechar esse caixa ele não poderá mais ser aberto hoje. Tem certeza que deseja
            fechá-lo?
          </span>
        </div>
        <div class="mt-5">
          <span class="mt-5 text-body-2"> Valor do caixa: {{ totalDoCaixa | currency }} </span>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text small :disabled="fechandoCaixa" color="error" @click="onClose">
          <v-icon small left> fa fa-close </v-icon>
          Cancelar
        </v-btn>
        <v-btn
          color="success white--text"
          depressed
          small
          :disabled="fechandoCaixa"
          :loading="fechandoCaixa"
          @click="() => fecharCaixa()"
        >
          <v-icon small left> fa fa-check </v-icon>
          Fechar caixa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import caixasService from "@/Services/CaixasService";

export default {
  name: "EFecharCaixaDialog",
  props: {
    caixa: {
      type: Object,
      default: null,
    },
    open: {
      type: Boolean,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters("Auth", ["user"]),
    totalDoCaixa() {
      return (
        parseFloat(this.somaTotal(this.mensalidades)) +
        parseFloat(this.somaTotal(this.situacoesCaixa)) -
        parseFloat(this.somaTotalSangria(this.sangriasCaixa))
      );
    },
  },

  mounted() {
    this.getMensalidades(this.user?.meu_caixa?.id, this.date);
    this.getSangriasCaixa(this.user?.caixa?.id, this.date);
  },

  watch: {
    open() {
      this.getMensalidades(this.user?.caixa?.id, this.date);
      this.getSangriasCaixa(this.user?.caixa?.id, this.date);
    },
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mensalidades: [],
      situacoesCaixa: [],
      sangriasCaixa: [],
      fechandoCaixa: false,
    };
  },

  methods: {
    async getMensalidades(caixa_id, date) {
      const response = await this.$services.movimentacaoService.getMensalidades(caixa_id, date);
      this.mensalidades = [...response.mensalidades];
      this.situacoesCaixa = response.situacoes;
    },

    async getSangriasCaixa(caixa_id, date) {
      const response = await this.$services.sangriaService.getSangriasDataAtual(caixa_id, date);
      this.sangriasCaixa = response;
    },

    async fecharCaixa() {
      try {
        this.fechandoCaixa = true;
        await caixasService.fecharCaixa(this.caixa, this.totalDoCaixa);
        this.caixa.estaAberto = false;
        this.onClose();
        this.$toast.success("Caixa fechado com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.fechandoCaixa = false;
      // this.$router.push({
      //  name: "home",
      // });
    },

    somaTotal(valores) {
      return valores.reduce((ac, vc) => {
        return parseFloat(parseFloat(ac) + parseFloat(vc.valor));
      }, 0);
    },

    somaTotalSangria(valores) {
      return valores.reduce((ac, vc) => {
        if (!vc.valor) return;
        return parseFloat(parseFloat(ac) + parseFloat(vc.valor));
      }, 0);
    },
  },
};
</script>
