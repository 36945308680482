<template>
  <div class="d-flex align-center">
    <template>
      <v-chip label :color="result === 'REPROVADO' ? 'error' : 'success'" small>
        {{ result }}
        <!-- <v-text-field
        :value="value"
        v-bind="{ outlined: false }"
        v-bind:style="{
          'min-width': '160px !important',
          'max-width': '160px !important',
        }"
        :disabled="true"
      /> -->
      </v-chip>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    notas: {
      type: [Array, Object],
    },
    notaComparativa: {
      type: Array,
    },
    circuito: {
      type: Number,
    },
    value: {
      type: [String, Number],
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
    colorNota: {
      type: String,
    },
  },
  data() {
    return {
      result: null,
    };
  },
  watch: {
    notaComparativa(val) {
      const number = this.arredondarValue(val[0]);
      if (this.matricula.id === val[1]) {
        if (number >= 7) {
          this.result = "APROVADO";
          this.$emit("atualizarValorNotaTexto", "APROVADO");
        } else {
          this.result = "REPROVADO";
          this.$emit("atualizarValorNotaTexto", "REPROVADO");
        }
      }
    },
  },
  mounted() {
    let number = 0;
    switch (this.circuito) {
      case 1:
        number = this.arredondarValue(this.notas[17].valor);

        break;
      case 2:
        number = this.arredondarValue(this.notas[43].valor);

        break;
      case 3:
        number = this.arredondarValue(this.notas[66].valor);
        break;

      default:
        break;
    }

    if (number >= 7) {
      this.result = "APROVADO";
    } else {
      this.result = "REPROVADO";
    }
  },
  methods: {
    arredondarValue(number) {
      // Separa a parte inteira e decimal do número
      const integerPart = Math.floor(number);
      const decimalPart = number - integerPart;

      // Define os limites para cada arredondamento
      const lowerLimit = 0.3;
      const upperLimit = 0.7;

      const deci = parseFloat(decimalPart.toFixed(1));

      // Verifica em qual intervalo o número está e arredonda conforme necessário
      if (deci >= lowerLimit && deci <= upperLimit) {
        return integerPart + 0.5; // arredonda para baixo
      }
      if (deci >= 0.8) {
        return Math.ceil(number).toFixed(1);
      }

      return Math.floor(number).toFixed(1);
    },
  },
};
</script>
