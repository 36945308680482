<template>
  <div class="d-flex align-center">
    <template
      v-if="
        $constants.situacoesMatriculaCondition[
          removerEspaco(matricula.situacao ? matricula.situacao : '')
        ]
      "
    >
      <v-currency-field
        locale="en-US"
        :default-value="null"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :decimal-length="1"
        :max="max"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{
          'min-width': '60px !important',
          'max-width': '60px !important',
        }"
        :disabled="true"
      />
    </template>
    <template v-else-if="tipoValor === 'valor_texto'">
      <!-- {{ notaTexto.valores_texto }} -->
      <v-select
        v-if="notaTexto"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="notaTexto.valor_texto"
        label="Selecione um tipo"
        outlined
      ></v-select>
      <v-select
        v-else-if="valorSelect"
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        :value="valorSelect"
        label="Selecione um tipo"
        outlined
      ></v-select>
      <v-select
        v-else
        @change="verificarVal($event)"
        :items="optionsNotaTexto"
        :item-value="(value) => value.descricao"
        :item-text="(value) => value.descricao"
        label="Selecione um tipo"
        outlined
      ></v-select>
    </template>
    <template v-else>
      <v-currency-field
        locale="en-US"
        :default-value="null"
        @input="(val) => handleInput(val)"
        :value="value || null"
        hide-details
        :allow-negative="false"
        dense
        :min="min"
        :max="max"
        :decimal-length="1"
        ref="input"
        v-bind="{ outlined: true }"
        v-bind:style="{ 'min-width': '60px !important', 'max-width': '60px !important' }"
        :class="{ 'campo-desabilitado': disabled }"
        @click="mostrarAlerta"
      />
      <!-- :maxlength="2" -->
      <v-btn
        icon
        small
        v-show="value === null && value !== undefined && !disabled"
        color="error"
        class="red lighten-5 ml-2"
        @click="emitNull"
      >
        <v-icon x-small>fa fa-close</v-icon>
      </v-btn>
    </template>
    <e-modal-info
      @closeModal="showModal = false"
      :dadosModal="dadosModal"
      :showModal="showModal"
    ></e-modal-info>
    <v-snackbar v-model="snackbar" color="warning">
      {{ mensagemAlerta }}
    </v-snackbar>
  </div>
</template>

<script>
import EModalInfo from "@/components/EModalInfo.vue";

export default {
  components: {
    EModalInfo,
  },
  props: {
    value: {
      type: [String, Number],
    },
    tipoValor: {
      type: [String],
    },
    circuitoId: {
      type: Number,
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: 10,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    matricula: {
      type: Object,
      required: true,
    },
    notaTexto: {
      type: Object,
      required: false,
    },
    notaTextoLetra: {
      type: String,
    },
    etapaAtual: {
      type: Number,
    },
    valMax: {
      type: Number,
      default: null,
    },
  },
  watch: {
    etapaAtual(value) {
      if (this.notaTextoLetra) {
        this.valorSelect = this.notaTextoLetra;
      } else {
        this.valorSelect = value;
      }
    },
  },
  data() {
    return {
      optionsNotaTexto: [],
      valorSelect: null,
      dadosModal: {
        titulo: null,
        mensagem1: null,
        mensagem2: null,
        botao: null,
      },
      showModal: false,
      snackbar: false,
      mensagemAlerta: "Não é permitido inserir nota nesse campo!.",
    };
  },
  mounted() {
    this.getOptionsSelect();
  },
  methods: {
    mostrarAlerta() {
      if (this.disabled) {
        this.snackbar = true;
      }
    },
    exibirModal(maximo) {
      this.dadosModal = {
        titulo: `Ops! O valor digitado é muito alto`,
        mensagem1: `Insira um valor igual ou abaixo de ${maximo}`,
        botao: "Ok",
      };
      this.showModal = true;
    },
    verificarVal($event) {
      this.$emit("add", $event);
    },
    async getOptionsSelect() {
      const circuito = await this.$services.circuitoNotasService.getCircuito(this.circuitoId);
      this.optionsNotaTexto = circuito.valores_notas_texto;
    },
    handleInput(val) {
      // const notasSplit = val.toString();
      // const arr = notasSplit.split(".");

      /*
      if (arr[0] !== "0") {
        if (arr[1] === "0" || arr[1] === "1" || arr[1] === "2") {
          val = `${arr[0]}.${0}`;
        } else if (
          arr[1] === "3" ||
          arr[1] === "4" ||
          arr[1] === "5" ||
          arr[1] === "6" ||
          arr[1] === "7"
        ) {
          val = `${arr[0]}.${5}`;
        } else if (arr[1] === "8" || arr[1] === "9") {
          const result = parseInt(arr[0], 10) + 1;
          val = `${result}.0`;
        }
      } */

      if (val > this.valMax) {
        console.log("val", val);
        // this.$emit("anularValor");
        this.exibirModal(this.valMax);
      } else if (val < this.min) {
        this.$refs.input.$emit("input", this.min);
      } else {
        this.$emit("input", parseFloat(val, 2));
        this.$emit("change", parseFloat(val, 2));
      }

      // if (val > this.max) {
      //   this.$refs.input.$emit("input", this.max);
      // } else if (val < this.min) {
      //   this.$refs.input.$emit("input", this.min);
      // } else {
      //   this.$emit("input", parseFloat(val, 10));
      //   this.$emit("change", parseFloat(val, 10));
      // }
    },
    emitNull() {
      // this.$emit("input", undefined);
      // this.$emit("change", undefined);
      setTimeout(() => {
        // eslint-disable-next-line
        this.$refs.input._data.formattedValue = "";
      }, 150);
    },
    removerEspaco(string) {
      // return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
  },
};
</script>

<style scoped>
/* Estilo para indicar campo desabilitado */
.campo-desabilitado {
  background-color: rgb(117, 117, 115); /* Cor de fundo desativada */
  color: #999; /* Cor de texto desativada */
  cursor: not-allowed; /* Cursor desativado */
}
</style>
