import RelatorioListarTurmaPage from "@/Pages/RelatorioFinanceiro/ListarTurmaPage.vue";
import lancarRelatorioBNCCPage from "@/Pages/RelatorioFinanceiro/lancarRelatorioBNCCPage.vue";
import relatorioPage from "@/Pages/RelatorioFinanceiro/index.vue";

export default [
  {
    path: "/relatorioFinanceiro/:franquiaId",
    name: "relatorioFinanceiro",
    component: relatorioPage,
  },
  {
    path: "/relatorioFinanceiro/visualizar/:gestaoDeAulasId/:turmaId",
    name: "relatorioFinanceiro.visualizar",
    component: RelatorioListarTurmaPage,
  },
  {
    path: "/relatorioFinanceiro/lancarRelatorioBNCC/:matricula_id",
    name: "relatorioFinanceiro.lancarRelatorioBNCC",
    component: lancarRelatorioBNCCPage,
  },
];
